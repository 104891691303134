import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogCard from "../components/blog/blogcard";
import Header from '../components/blog/header';


const BlogPage = ({data, path}) => (
  <Layout
    title="Blog" subtitle={'Founder insights, product guides, customer stories, video demos, and more!'}
    includeBanner={false} lightHeader={false}
    seoComponent={<SEO path={path} title="Blog" description="Founder insights, product guides, customer stories, video demos, and more!" />}
  >
    {/*<h2 className="text-5xl font-extrabold text-center">GummySearch Insights</h2>
    <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4 text-center">
      Achieve your goals with helpful process & product walkthroughs
    </p>*/}

    <div className="content-body markdown-body">
      <Header title="GummySearch Blog"
       subtitle="Founder insights, product guides, video demos, and more!"
      />
    </div>

    {data.recentWp.nodes.length ? (
      <div className="mx-auto mt-12">
        <div id="recent" className="">
          <h2 className="text-3xl font-semibold flex items-center">Recently Published</h2>
          <div className="">
            <ul className={`mt-4 grid sm:grid-cols-3 gap-x-8 gap-y-4`}>
              {data.recentWp.nodes.map((node, index) => (
                <BlogCard key={node.slug} node={node} mini={true}/>
              ))}
            </ul>
          </div>
        </div>
      </div>
    ) : ''}

    <div id="how-to" className="mt-12 mb-20">
      <h2 className="text-3xl font-semibold flex items-center">
        Most Popular
      </h2>
      <div className="mt-4">
        <ul className={`grid sm:grid-cols-3 sm:gap-x-8 gap-y-8`}>
          {data.popular.edges.map((edge, index) => (
            <BlogCard key={edge.node.frontmatter.slug} node={edge.node} mini={false} />
          ))}
        </ul>
      </div>
    </div>
    
    <div id="how-to" className="mt-12">
      <h2 className="text-3xl font-semibold flex items-center">
        <Link className="cursor-pointer hover:underline" to="/how-to/">GummySearch Product Guides</Link>
        
      </h2>
      <div className="mt-4">
        <ul className={`grid sm:grid-cols-3 sm:gap-x-8 gap-y-4`}>
          {data.howtos.edges.map((edge, index) => (
            <BlogCard key={edge.node.frontmatter.slug} node={edge.node} mini={false} />
          ))}
        </ul>
        <div className="p-4 text-center w-full">
          <Link to="/how-to/" className="text-sm opacity-75 hover:opacity-100 text-cyan-500 font-semibold cursor-pointer">
            See All {data.howtos.totalCount}
          </Link>
        </div>
      </div>
    </div>

    <div id="blog" className="mt-20">
      <h2 className="text-3xl font-semibold flex items-center">
        <Link className="cursor-pointer hover:underline" to="/insights/">Founder Insights</Link>
        
      </h2>
      <div className="mt-4">
        <ul className={`grid sm:grid-cols-3 sm:gap-x-8 gap-y-4`}>
          {data.insights.edges.map((edge, index) => (
            <BlogCard key={edge.node.frontmatter.slug} node={edge.node} mini={false} />
          ))}
        </ul>
        <div className="p-4 text-center w-full">
          <Link to="/insights/" className="text-sm opacity-75 hover:opacity-100 text-cyan-500 font-semibold cursor-pointer">
            See All {data.insights.totalCount}
           </Link>
        </div>
      </div>
    </div>

    <div id="demos" className="mt-20">
      <h2 className="text-3xl font-semibold flex items-center">
        <Link className="cursor-pointer hover:underline" to="/demos/">60s Demo Videos</Link>
        
      </h2>
      <div className="mt-4">
        <ul className={`grid sm:grid-cols-3 sm:gap-x-8 gap-y-4`}>
          {data.demos.edges.map((edge, index) => (
            <BlogCard key={edge.node.frontmatter.slug} node={edge.node} mini={false} />
          ))}
        </ul>
        <div className="p-4 text-center w-full">
          <Link to="/demos/" className="text-sm opacity-75 hover:opacity-100 text-cyan-500 font-semibold cursor-pointer">
            See All {data.demos.totalCount}
          </Link>
        </div>
      </div>
    </div>

    {/* <div id="podcasts" className="mt-20">
      <h2 className="text-3xl font-semibold flex items-center">
        <Link className="cursor-pointer hover:underline" to="/demos/">Podcast Episodes</Link>
        
      </h2>
      <div className="mt-4">
        <ul className={`grid sm:grid-cols-3 sm:gap-x-8`}>
          {data.podcasts.edges.map((edge, index) => (
            <BlogCard key={edge.node.frontmatter.slug} node={edge.node} mini={false} />
          ))}
        </ul>
        <div className="p-4 text-center w-full">
          <Link to="/podcasts/" className="text-sm opacity-75 hover:opacity-100 text-cyan-500 font-semibold cursor-pointer">
            See All {data.podcasts.totalCount}
          </Link>
        </div>
      </div>
    </div> */}

    {/*<div id="spotlight" className="mt-20">
      <h2 className="text-3xl font-semibold flex items-center">
        <Link className="cursor-pointer hover:underline" to="/spotlight/">Customer Spotlight</Link>
        {data.spotlights.edges.length >= 3 ? (
          <Link to="/spotlight/" className="ml-auto text-sm opacity-75 hover:opacity-100 text-cyan-500 font-semibold cursor-pointer">
            See All {data.spotlights.totalCount}
          </Link>
        ) : ''}
      </h2>
      <div className="mt-4">
        <ul className={`grid sm:grid-cols-3 sm:gap-x-8`}>
          {data.spotlights.edges.map((edge, index) => (
            <BlogCard key={edge.node.frontmatter.slug} node={edge.node} mini={false} />
          ))}
        </ul>
      </div>
    </div>*/}

  </Layout>
)

export const query = graphql`
query {
  insights: allMdx(
    filter: { frontmatter: { type : {eq: "insight"}, published: {ne: ""}}},
    sort: { order: ASC, fields: [frontmatter___published] }
    limit: 3
  ) {
    totalCount
    edges {
      node {
        frontmatter {
          slug
          published
          author
          title
          type
          description
          imageUrl
        }
      }
    }
  }
  howtos: allMdx(
    filter: { frontmatter: { type : {eq: "how-to"}, published: {ne: ""}}},
    sort: { order: ASC, fields: [frontmatter___order] }
    limit: 3
  ) {
    totalCount
    edges {
      node {
        frontmatter {
          slug
          published
          author
          title
          type
          description
          imageUrl
        }
      }
    }
  }
  spotlights: allMdx(
    filter: { frontmatter: { type : {eq: "spotlight"}, published: {ne: ""}}},
    sort: { order: DESC, fields: [frontmatter___published, frontmatter___slug] }
    limit: 3
  ) {
    totalCount
    edges {
      node {
        frontmatter {
          slug
          published
          author
          authorImage
          title
          type
          description
          imageUrl
        }
      }
    }
  }
  demos: allMdx(
    filter: { frontmatter: { type : {eq: "demo"}, published: {ne: ""}}},
    sort: { order: DESC, fields: [frontmatter___order] }
    limit: 3
  ) {
    totalCount
    edges {
      node {
        frontmatter {
          slug
          published
          author
          authorImage
          title
          type
          description
          imageUrl
        }
      }
    }
  }
  podcasts: allMdx(
    filter: { frontmatter: { type : {eq: "podcast"}, published: {ne: ""}}},
    sort: { order: DESC, fields: [frontmatter___published] }
    limit: 3
  ) {
    totalCount
    edges {
      node {
        frontmatter {
          slug
          published
          author
          authorImage
          title
          type
          description
          imageUrl
        }
      }
    }
  }
  popular: allMdx(
    filter: { frontmatter: { popular: {eq: true}}},
    sort: { order: ASC, fields: [frontmatter___published] }
    limit: 6
  ) {
    totalCount
    edges {
      node {
        frontmatter {
          slug
          published
          author
          authorImage
          title
          type
          description
          imageUrl
        }
      }
    }
  }
  recentWp: allWpPost(
    sort: {fields: date, order: DESC}
    limit: 9
  ) {
    nodes {
      id
      date
      slug
      title
      status
      excerpt
      dateGmt
      tags {
        nodes {
          name
          slug
          count
        }
      }
      featuredImage {
        node {
          link
        }
      }
      author {
        node {
          name
          slug
          firstName
          avatar {
            url
          }
        }
      }
    }
  }
}
`

export default BlogPage;
